.rowSection {
  .contactUsRow {
    .parallax {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .circularSection {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      // z-index: -1;
      .circularText {
        right: 8vw;
        top: 10vw;

        // width: fit-content;
        height: auto;

        @media (max-width: 991px) {
          width: 20vw;
          top: 4vw;
          right: 6vw;
        }
      }
    }
    &.divSection {
      background-color: theme-color('primary');
      @media (max-width: 991px) {
        border-radius: 0;
        padding-top: 6vw;
        padding-bottom: 6vw;
      }
    }
    .contactUsCol {
      &.contactUsColTitle {
        h2 {
          color: theme-color('primary');
        }
      }
      &.contactUsColImgs {
        position: relative;
        z-index: 1;
        picture {
          .contactUsImg {
            position: relative;
            width: 20vw;

            height: auto;
          }
          &:nth-child(1) {
            position: absolute;

            width: 100%;
            height: 100%;
            left: 0;
            top: 0;

            z-index: -1;
            .contactUsImg {
              width: 20vw;
            }
          }
          &:nth-last-child(1) {
            position: absolute;

            width: 30vw;
            right: 0;
            bottom: 0;

            z-index: -2;
            .contactUsImg {
              width: 100%;
            }
          }
        }
      }
    }
    @media (max-width: 991px) {
      // z-index: 1;
      picture,
      .circularSection {
        // position: unset;
        pointer-events: none;
      }
      .circularSection {
        // position: unset;
        mix-blend-mode: difference;
        pointer-events: none;
        z-index: 1;
      }
    }
  }
}
