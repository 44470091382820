.rowSection {
  .aboutMeCol {
    .aboutMeList {
      .animatedScrollCard {
        border-color: theme-color('primary');
        z-index: 1;

        mix-blend-mode: multiply;

        .circularSection {
          position: absolute;
          width: 8vw;
          height: 8vw;
          top: 50%;
          right: -4vw;
          transform: translateY(-50%);
          z-index: -1;
          .circularText {
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
          }

          @media (max-width: 991px) {
            width: 15vw;
            height: 15vw;

            right: -7.5vw;
          }
        }
      }
      .cardStyle {
        box-shadow: none;
      }
    }
    &.aboutMeColTitle {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // margin-bottom: 3em;
      position: relative;
      #textContentSticky {
        // position: sticky;
        // position: -webkit-sticky;
        // top: 50%;
        // transform: translateY(-50%);
        position: relative;
        height: 100%;
        width: 100%;
        img {
          position: absolute;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%, -50%);
          height: auto;
          // width: 10vw;
        }
        @media (max-width: 1600px) and (min-width: 992px) {
          padding-right: 3vw;
        }
      }

      .divToSticky {
        margin-top: 3em;
      }

      // .sidebarAnimation {
      //   transition: transform 0.5s;
      //   &.sidebarUp {
      //     // transform: translateY(30px);
      //     padding-top: 30px;
      //   }

      //   &.sidebarDown {
      //     // transform: translateY(130px);
      //     padding-top: 130px;
      //   }
      // }
    }

    &.aboutMeColDesc {
      margin-bottom: 6vw;
      p {
        margin-bottom: 0;

        color: theme-color('primary');
      }

      @media (max-width: 991px) {
        margin-bottom: 12vw;
      }
    }

    .button-wrapper {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      .magnetic-wrap {
        width: fit-content;
      }
    }

    .cardStyle {
      background-color: theme-color('bgColor') !important;
      transition: background-color 0.3s;
      position: relative;
      border: none;

      margin-bottom: 1vw;

      opacity: 0.2;

      h5 {
        color: theme-color('primary');

        margin-bottom: 0;
        @media (max-width: 991px) {
          text-align: left;

          font-size: 4vw;
          line-height: 4.5vw;
        }
      }

      &:nth-last-child(1) {
        // margin-bottom: 0 !important;
        margin-bottom: 0;
      }
      &.active {
        opacity: 1;
        .stuationCardStar {
          display: block;
        }
        .h5 {
          opacity: 1;
          color: theme-color('textColorLight') !important;
        }
      }
      padding: 3vw !important;
      width: 100%;
      // padding: 30px auto;

      @media (max-width: 991px) {
        & {
          padding: 5vw;
          .h5 {
            // padding: 0;
          }
        }
      }

      .h5 {
        // font-family: 'Roboto';
        // font-style: normal;
        // font-weight: normal;
        // font-size: 40px;
        // line-height: 45px;

        opacity: 0.2;
      }

      @media (max-width: 991px) {
        margin-bottom: 3vw;
        & {
          width: 100%;
        }
      }
    }
    .animatedScrollCard {
      // width: 100%;
      // height: 100%;
      // border: 1px solid theme-color('secondary');
      // position: absolute;
      // padding: 5em;

      display: flex;
      align-items: center;
      position: absolute;

      top: 0;
      left: 50%;
      transform: translateX(-50%);
      // border: 3px solid theme-color('secondary');
      border-radius: 20px;

      transition: top 0.3s;

      .stuationCardStar {
        // display: none;
        // width: 90px;
        // height: 90px;
        // position: absolute;
        left: -45px;

        @media (max-width: 991px) {
          left: -4vw;
        }
      }

      @media (max-width: 991px) {
        img {
          object-position: right;
        }
      }
    }

    @media (max-width: 991px) {
      h4 {
        font-size: 4.5vw;
        line-height: 4.7vw;
      }
    }
  }
}
