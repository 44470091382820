.row {
  margin-left: 0;
  margin-right: 0;
}
.row {
  .colHorizontalPadding {
    &:nth-child(1) {
      padding-right: 4vw;
    }
    &:nth-child(2) {
      padding-left: 4vw;
    }
  }
}

.fslightbox-open {
  body {
    margin-right: 0 !important;
  }
}
.bgMain {
  position: relative;
  overflow: hidden;
  background-color: red;
  // BACKGROUND TEXTURE
  // &::before {
  //   content: '';
  //   background: -webkit-image-set(
  //     url('../../imgs/background/paperBgMobile-min.jpg') 2x,
  //     url('../../imgs/background/paperBg-min.jpg') 1x
  //   );
  //   background-image: image-set(
  //     url('../../imgs/background/paperBgMobile-min.jpg') 2x,
  //     url('../../imgs/background/paperBg-min.jpg') 1x
  //   );

  //   // background-image: url('../../imgs/background/paperBg-min.jpg');
  //   // background-repeat: no-repeat;
  //   background-size: contain;
  //   background-position: top;
  //   position: absolute;
  //   top: 0px;
  //   right: 0px;
  //   bottom: 0px;
  //   left: 0px;
  //   opacity: 0.5;
  //   z-index: 0;
  // }

  .sectionsWrapper {
    width: 100%;
    @media (max-width: 991px) {
      padding-left: 0;
    }
  }
}

.cursorPointer {
  cursor: pointer;
}

.sectionContainer {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  &.sectionBackground {
    background-color: theme-color('cardBgOpacity');
    border-radius: 15vw 0;
  }
}
.sectionPadding {
  padding: $sectionsPadding 0;
  @media (max-width: 991px) {
    padding: $sectionsPaddingMobile 0;
  }
}

// CUSTOM SPACINGS
$customMarginDefault: 3vw;
.customMarginDefault {
  &.top {
    margin-top: $customMarginDefault;
  }
  &.topx2 {
    margin-top: calc(calc(#{$customMarginDefault} * 2));
  }

  &.vertical {
    margin: $customMarginDefault 0;
  }
}

.customPaddingDefault {
  &.horizontal {
    padding: 0 3vw;
  }
  &.vertical {
    padding: 3vw 0;
  }
}
//

.sectionRowPadding {
  .sectionColPadding {
    &:nth-child(1) {
      padding-right: 5vw;
    }
    &:nth-child(2) {
      padding-left: 5vw;
    }
  }
}

.rowSection {
  width: 100%;
  max-width: 80%;

  .rowContainer {
    width: 100%;
  }

  & > .row {
    width: 100%;
  }

  // @media (max-width: 1600px) {
  //   max-width: 90%;
  // }
  @media (max-width: 1600px) {
    max-width: 90%;
  }
  @media (max-width: 991px) {
    max-width: 90%;
  }
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  // DEFAULT
  width: 100%;
  height: 100%;
  object-fit: contain;

  border-radius: $borderDefault;
  &.borderRadiusImg {
    border-radius: 20px;
  }
}

@media (max-width: 991px) {
  .row {
    margin-right: 0;
    margin-left: 0;
  }
  .rowSection {
    // width: 100%;
    // height: 100%;
    max-width: 90%;
  }
}
.playContainerWrapper {
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;

  cursor: pointer;

  top: 0;
  left: 0;
  &:hover {
    .playContainer {
      background-color: transparent;
      border-color: theme-color('primary');
    }
  }
}
.playContainer {
  max-width: 140px;
  max-height: 140px;
  width: 6vw;
  height: 6vw;
  z-index: 1;

  pointer-events: none;

  background: transparent;
  border: 1px solid theme-color('textColor');

  border-radius: 50%;

  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  transition: background-color 0.3s;
  &.playContainerSmaller {
    max-width: 100px;
    max-height: 100px;
    width: 4vw;
    height: 4vw;
    .playIcon {
      font-size: 1vw;
    }
    @media (max-width: 991px) {
      width: 9vw;
      height: 9vw;
      .playIcon {
        font-size: 3.2vw;
      }
    }
  }

  .playIcon {
    color: theme-color('textColor') !important;
    font-size: 1.2vw;

    path {
      color: theme-color('textColor') !important;
    }
  }

  &:hover {
    background: transparent;
    border-color: theme-color('secondary');
  }

  @media (max-width: 991px) {
    width: 13vw;
    height: 13vw;
    .playIcon {
      font-size: 3.2vw;
    }
  }
}
.cardDeckSquare {
  -webkit-flex-flow: row;
  justify-content: left;
}
.cardSquare {
  // flex: 1 0 auto !important;
  height: auto;

  position: relative;
  &::before {
    content: '';
    float: left;
    padding-top: 100%;
  }

  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    h5 {
      text-align: center;
    }
  }
}
.cardStyle,
.divSection {
  position: relative;
  background-color: theme-color('cardBg');
  border: none;

  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25);

  padding: 3vw;

  border-radius: $borderDefault;

  &.cardStyleLight {
    background-color: theme-color('primary');
    border: none;
  }
  &.cardStyleOpacity {
    background-color: theme-color('cardBgOpacity');
    border: none;
  }
  &.cardStyleTransparent {
    background-color: transparent;
    border: none;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }

  5% {
    transform: scale(1);
  }

  10% {
    transform: scale(0.75);
  }

  15% {
    transform: scale(1);
  }

  20% {
    transform: scale(0.75);
  }

  100% {
    transform: scale(0.75);
  }
}

// Margins

.marginBetween {
  margin: 3vw 0;
}
.marginTop {
  margin: 3vw 0 0 0;
}
.marginBottom {
  margin: 0 0 3vw 0;
}

// GRID EQUAL HEIGHT

.cardsGrid {
  display: grid;
  grid-auto-rows: 1fr;
  .cardStyle {
    height: 10vw;

    display: flex;
    justify-content: center;
    align-items: center;
    .cardBody {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    ul {
      margin-bottom: 0;
    }

    @media (max-width: 991px) {
      height: 20vw;
    }
  }
}

.Overview__svg {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  overflow: visible;
}
.circularSection {
  position: relative;
  .circularText {
    width: 10vw;
    position: absolute;
  }
}

// Sticky Elements

.stickyElementCenter {
  position: sticky;
  top: 30px;

  transition: padding-top 0.3s;

  &.stickyElementNav {
    // top: calc(72px + 30px);
    padding-top: 72px;
  }
  & > div {
    position: relative;
    // transform: translateY(-50%);
  }
}

.infiniteRotation {
  -webkit-animation: rotating 6s linear infinite;
  -moz-animation: rotating 6s linear infinite;
  -ms-animation: rotating 6s linear infinite;
  -o-animation: rotating 6s linear infinite;
  animation: rotating 6s linear infinite;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
