$magnetic-size: 2em;
$magnetic-size2: 2em;

a,
button {
  &:hover,
  &:focus {
    text-decoration: none;
    box-shadow: none;
    outline: none;
  }
}
.btnWrap {
  width: 100%;
  text-align: center;
  padding: 3vw 0;
}
.btnGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .buttonStyle {
    &:nth-child(1) {
      margin-right: 1vw;
    }
    &:nth-last-child(1) {
      margin-left: 1vw;
    }
  }
  @media (max-width: 991px) {
    flex-wrap: wrap;
    .buttonStyle {
      width: 100%;
      flex: none;
      &:nth-last-child(1) {
        margin-top: 4vw;
      }
    }
  }
}
.buttonStyle {
  padding: 1vw 4vw;
  flex: 1;
  text-align: center;

  border: 1px solid theme-color('primary');

  border-radius: 1vw 0;

  background: theme-color('buttonsGradient');
  background-color: theme-color('buttonsBg2');
  color: theme-color('buttonsColor');

  position: relative;
  z-index: 1;
  overflow: hidden;

  &.btnStyleFullWidth {
    width: 100%;
  }

  // &.btnSecondaryDark {
  //   background-color: theme-color('buttonsBgdark');
  //   color: theme-color('buttonsColor2');
  // }
  // &.btnSecondary {
  //   background-color: theme-color('buttonsBg2');
  //   color: theme-color('buttonsColor');
  // }
  &::after {
    content: '';
    background: theme-color('bgColor');
    position: absolute;
    z-index: -1;
    padding: 0.85em 0.75em;
    display: block;

    left: -20%;
    right: -20%;
    top: 0;
    bottom: 0;
    transform: skewX(-45deg) scale(0, 1);

    transition: all 0.3s ease;
  }
  @media (min-width: 992px) {
    &:hover {
      color: theme-color('primary') !important;
      &::after {
        transform: skewX(-45deg) scale(1, 1);
        transition: all 0.3s ease-out;
      }
    }
  }
  @media (max-width: 991px) {
    &:active {
      color: theme-color('primary');
      &::after {
        transform: skewX(-45deg) scale(1, 1);
        transition: all 0.3s ease-out;
      }
    }
  }

  @media (max-width: 991px) {
    padding: 3vw 8vw;

    border-radius: 4vw 0;
  }
}

.magnetic-wrap {
  position: relative;
  display: inline-block;
  position: relative;

  .js-magnetic-area {
    // pointer-events: none;
    cursor: pointer;
  }
}

.magnetic-size {
  position: absolute;
  bottom: -$magnetic-size;
  top: -$magnetic-size;
  left: -$magnetic-size;
  right: -$magnetic-size;
  z-index: 1; // The area has to be on top
  border-radius: 15px;
  // border: 2px dashed #333; // Guideline to visualize the area
}

.size1 {
  margin: $magnetic-size;
}
.size2 {
  margin: $magnetic-size2;
}
