.RootServices {
  .rowSection {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  padding-bottom: 0;
}
