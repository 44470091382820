.rowSection {
  .servicesRow {
    display: flex;
    justify-content: center;
    align-items: center;

    &.servicesRowTitle {
      max-width: 80%;

      margin-bottom: 3vw;

      @media (max-width: 1600px) {
        max-width: 90%;
      }
    }
    &.servicesRowAnim {
      background-color: theme-color('primary');

      h5,
      p {
        color: theme-color('primary');
      }
      &:nth-child(2) {
        background-color: transparent;
      }
    }
    &.servicesRowAnimPrimary {
      background-color: theme-color('bgColor');
      h5,
      p {
        color: theme-color('textColorLight');
      }
    }
    .servicesCol {
      // max-width: 90%;
      padding-left: 0;
      padding-right: 0;
      position: relative;
      .divSectionAnim {
        box-shadow: none;
        position: relative;
        text-align: center;

        padding-bottom: 6vw;

        height: 100vh;
        background-color: transparent;

        .divImgsWrap {
          position: relative;
          top: -3vw;
          img {
            width: 30vw;
            position: relative;
            &.servicesImg1 {
              top: -3vw;
              left: 3vw;
            }
            &.servicesImg2 {
              // top: -3vw;
              right: 3vw;
            }

            @media (max-width: 991px) {
              width: 40vw;

              &.servicesImg1 {
                top: -6vw;
                left: 6vw;
              }
              &.servicesImg2 {
                // top: -3vw;
                right: 6vw;
              }
            }
          }
        }
        .contentWrap {
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          & > * {
            width: 100%;
          }
          * {
            z-index: 2;
          }
        }
        .divSection {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 50%;
          z-index: 1;

          transform: translateX(-50%) scaleX(0.8);

          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;

          box-shadow: none;

          border-radius: 30vw 30vw 0 0;
          @media (max-width: 1600px) {
            transform: translateX(-50%) scaleX(0.9);
          }
          @media (max-width: 991px) {
            transform: translateX(-50%) scaleX(1);
          }

          &.divSectionPrimary {
            background-color: theme-color('primary');
          }
        }
        h5 {
          width: fit-content;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 1.5vw;

          @media (max-width: 991px) {
            margin-bottom: 3vw;
          }
        }

        @media (max-width: 991px) {
          height: auto;
          padding-bottom: 20vw;
          padding-left: 6vw;
          padding-right: 6vw;
        }
      }
    }
  }
}
