.RootLegals {
  background-color: theme-color('primary');
  padding-top: 150px;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 5vw;
  p,
  li,
  span {
    color: theme-color('bgColor');
    opacity: 0.8;
    @media (max-width: 991px) {
      text-align: left;
    }
  }
  .container {
    a {
      color: theme-color('darkColor');
    }
  }
  h2,
  h2 *,
  h3,
  h3 *,
  h4,
  h4 * {
    color: theme-color('bgColor');
    @media (max-width: 991px) {
      text-align: center;
    }
  }
  h3 {
    margin-top: 2vw;
    margin-bottom: 2vw;
    color: theme-color('bgColor') !important;
    @media (max-width: 991px) {
      margin-top: 8vw;
      margin-bottom: 8vw;
    }
  }
  h4 {
    margin: 3vw 0;
    @media (max-width: 991px) {
      margin: 6vw 0;
    }
  }

  @media (max-width: 991px) {
    padding-top: 100px;
  }
  @media (max-width: 300px) {
    padding-top: 72px;
  }
}
