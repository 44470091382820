.RootContactUs {
  background-color: theme-color('bgColor');

  z-index: 1;
  .rowSection {
    padding-top: 3vw;
    margin-top: -20vw;

    @media (max-width: 991px) {
      padding-top: 6vw;
      max-width: 100%;
    }
  }
  @media (max-width: 991px) {
    padding-bottom: 0;
  }
}
