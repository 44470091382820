// FONTS
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
//
* {
  color: theme-color('primary');
}

h1 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 5vw;
  line-height: 5vw;
  letter-spacing: 0.04em;

  text-transform: uppercase;

  color: theme-color('textColorLight');

  &.h1Smaller,
  .h1Smaller {
    font-size: 4vw;
    line-height: 4vw;
    color: theme-color('textColorLight');

    @media (max-width: 991px) {
      font-size: 7.5vw;
      line-height: 6vw;
    }
  }

  @media (max-width: 991px) {
    font-size: 9.5vw;
    line-height: 8vw;
  }
}

h2,
h4,
h5,
p,
li {
  color: theme-color('textColor');
}

h2 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 4vw;
  line-height: 5vw;

  margin-bottom: 1em;

  text-transform: uppercase;

  // text-align: center;

  color: theme-color('textColorLight');

  @media (max-width: 991px) {
    font-size: 6vw;
    line-height: 7vw;
    text-align: center;
  }
}
h3 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5vw;
  line-height: 1.8vw;

  text-transform: uppercase;
  color: theme-color('textColor');

  &.h3Smaller {
    font-size: 1.5vw;
    line-height: 1.7vw;
  }

  @media (max-width: 991px) {
    font-size: 4vw;
    line-height: 5vw;
  }
}

h4 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  line-height: 2vw;

  margin-bottom: 0;

  @media (max-width: 991px) {
    font-size: 4vw;
    line-height: 4vw;
  }
}

h5 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 2vw;
  line-height: 2.5vw;
  /* identical to box height */

  text-transform: uppercase;
  &.h5Smaller {
    text-transform: unset;

    font-size: 1.5vw;
    line-height: 2vw;

    color: theme-color('primary');

    @media (max-width: 991px) {
      font-size: 3.5vw;
      line-height: 4vw;
    }
  }
  &.cardTitle {
    font-size: 1.3vw;
    line-height: 1.8vw;
    text-align: center;

    @media (max-width: 991px) {
      font-size: 3.3vw;
      line-height: 3.8vw;
    }
  }

  &.h5Roboto,
  .h5Roboto {
    font-family: 'Roboto';
  }

  @media (max-width: 991px) {
    font-size: 5vw;
    line-height: 5.5vw;
    text-align: center;
  }
}
.titleAnim,
.titleBgWrap {
  .titleHover,
  .titleBgEffect {
    position: relative;
    &::before,
    .bgHover {
      content: '';
      position: absolute;
      width: 0;
      height: 100%;
      left: -3vw;
      top: 0;
      background-color: theme-color('primary');
      opacity: 0.2;

      transition: width 0.3s;
    }
  }
  &:hover {
    .titleHover {
      &::before {
        width: calc(100% + 6vw);
      }
    }
  }
}

p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw;
  line-height: 1.6vw;

  &.pBiggest,
  .pBiggest {
    font-size: 1.6vw;
    line-height: 2vw;
    font-weight: 500;

    @media (max-width: 991px) {
      font-size: 3.6vw;
      line-height: 4vw;
    }
  }

  @media (max-width: 991px) {
    font-size: 3.1vw;
    line-height: 4.1vw;

    text-align: center;
  }
}

.leftText {
  text-align: left;
}

.boldText {
  font-weight: 800;
}
.regularText {
  font-weight: 400 !important;
}
.primaryText,
.primaryText * {
  color: theme-color('primary');
}
.darkText {
  color: theme-color('darkColor');
}
.lightText {
  color: theme-color('textColor');
}
.upperText {
  text-transform: uppercase;
}
.lowerText {
  text-transform: initial;
}

.openSansFont {
  font-family: 'Open Sans';
}

.buttonText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 1.3vw;
  line-height: 1.9vw;

  text-transform: uppercase;

  &.buttonTextBiggest {
    font-size: 1.8vw;
    line-height: 2.4vw;

    @media (max-width: 991px) {
      font-size: 4.8vw;
      line-height: 5.4vw;
    }
  }

  @media (max-width: 991px) {
    font-size: 4.3vw;
    line-height: 4.9vw;
  }
}

// FOOTER
.footerTitle,
.footerText {
  font-size: 1vw;
  line-height: 1.2vw;

  color: theme-color('textColorLight');
  * {
    color: theme-color('textColorLight');
  }

  &.listGroupText {
    text-transform: uppercase;
  }

  @media (max-width: 991px) {
    font-size: 4vw;
    line-height: 4.5vw;
  }
}
.footerTitle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 2.1vw;
  line-height: 2.1vw;
  @media (max-width: 991px) {
    font-size: 4.1vw;
    line-height: 4.1vw;
  }
}

.footerText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2vw;
  line-height: 1.6vw;
  letter-spacing: 0.02em;

  &.footerLegalText {
    font-size: 0.8vw;
    line-height: 1vw;

    @media (max-width: 991px) {
      font-size: 2.8vw;
      line-height: 3vw;
    }
  }

  @media (max-width: 991px) {
    font-size: 3.2vw;
    line-height: 3.6vw;
  }
}

.allRights {
  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1vw;
    line-height: 1.4vw;
    text-transform: uppercase;

    color: theme-color('textColorLight');

    text-align: center;

    margin-top: 1.5vw;

    a {
      color: theme-color('primary');
      transition: color 0.3s;

      &:hover {
        text-decoration: none;
        color: theme-color('textColor');
      }
    }
    @media (max-width: 991px) {
      font-size: 3vw;
      line-height: 3.4vw;

      margin-top: 7vw;
    }
  }
}

// NAVBAR
.navLink {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 1vw;
  line-height: 1vw;

  color: theme-color('textColorLight');
  * {
    color: theme-color('textColorLight');
  }

  @media (max-width: 991px) {
    font-size: 5vw;
    line-height: 4vw;
  }
}
