$imgBorderRadius: 5vw;

.RootHome {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  // padding-left: $nav-height;

  // &::before {
  //   content: '';
  //   background-color: rgba(0, 0, 0, 0.4);

  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   z-index: 2;
  // }

  .heroBgImg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    object-fit: cover;
    width: 100%;
    height: 100%;
    // border-radius: 0 0 $imgBorderRadius 0;
    z-index: 1;
    padding: 0;
  }

  .rowSection {
    position: relative;
    width: 100%;
    height: 100vh;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    z-index: 3;
    max-width: 90%;
  }

  .circularSection {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    // z-index: -1;
    .circularText {
      max-width: 300px;
      right: 20%;
      bottom: 3vw;

      // width: fit-content;
      height: auto;

      @media (max-width: 991px) {
        max-width: 150px;
        width: 25vw;
        right: auto;
        bottom: auto;
        bottom: 5vw;
      }
    }

    @media (max-width: 991px) {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
  }
  @media (max-width: 991px) {
    & {
      // padding-top: 15vw;
      padding-left: 0;

      .rowSection {
        // height: auto;
        padding-bottom: 0;
        align-content: center;
        // max-width: 85%;
        // margin-top: 110px;
        // margin-bottom: 5vw;
      }
    }
  }
}
