#loader-wrapper {
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1031;
}
#loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  // width: 150px;
  // height: 150px;
  // margin: -75px 0 0 -75px;

  // -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  // animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */

  z-index: 1032;

  & > div {
    position: relative;
    // animation: leaves 1s infinite linear;
  }

  //  -------
  // FOR LOADING PROGRESS PAINT:
  // .laoderFooterPrintLoading {
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   background-color: theme-color('primary');
  //   mask: url(../../imgs/loader/loader.svg);
  //   mask-position: left;
  //   mask-repeat: no-repeat;
  //   mask-size: cover;
  //   max-width: 150px;
  //   animation: loading linear;
  //   animation-play-state: paused;
  // }
  // @keyframes loading {
  //   0% {
  //     max-width: 0;
  //   }
  // }
  //  -------

  .loaderSvg {
    max-width: 400px;
    width: 30vw;
    height: auto;
    stroke-dasharray: 0;
    stroke-dashoffset: 0;

    @media (max-width: 991px) {
      width: 60vw;
    }
  }
}
@keyframes leaves {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
  // 100% {
  //   transform: scale(0.75);
  // }
}

#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: theme-color('bgColor');
  z-index: 1031;
  -webkit-transform: translateY(0); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateY(0); /* IE 9 */
  transform: translateY(0); /* Firefox 16+, IE 10+, Opera */
}

#loader-wrapper .loader-section.section-left {
  left: 0;
}

#loader-wrapper .loader-section.section-right {
  right: 0;
}

#content {
  margin: 0 auto;
  padding-bottom: 50px;
  width: 80%;
  max-width: 978px;
}

/* JavaScript Turned Off */
.no-js .pageContainer#loader-wrapper {
  display: none;
}
/* Loaded */
.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateY(-100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateY(-100%); /* IE 9 */
  transform: translateY(-100%); /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateY(100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateY(100%); /* IE 9 */
  transform: translateY(100%); /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.loaded #loader-wrapper {
  visibility: hidden;

  -webkit-transform: translateY(-100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateY(-100%); /* IE 9 */
  transform: translateY(-100%); /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}
