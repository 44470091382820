.RootAboutMe {
  // padding-bottom: 0;
  width: 100%;
  padding-top: 7.5vw;
  padding-bottom: 5vw;
  margin-top: 50px;

  justify-content: center;
  .rowSection {
  }
}
