.rowSection {
  .homeCol {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3vw;
    // &:nth-child(1) {
    //   padding-right: 5vw;
    // }
    // &:nth-child(2) {
    //   padding-left: 5vw;
    // }

    & > div {
      width: 100%;
      // padding-bottom: 3vw;

      h3 {
        color: theme-color('textColorLight');
      }

      .btnGroup {
        width: fit-content;
        margin-left: auto;

        @media (max-width: 991px) {
          width: 100%;
          margin-left: 0;

          display: flex;
          justify-content: center;
          align-items: center;

          .buttonStyle {
            width: fit-content;
            text-align: center;
          }
        }
      }

      h1 {
        width: max-content;
      }

      @media (max-width: 991px) {
        text-align: center;
        h1 {
          width: unset;

          text-align: center;
        }
      }
    }

    h2 {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 3vw;
    }
    @media (max-width: 991px) {
      justify-content: center;
      * {
        text-align: left;
        h3 {
          text-align: center;
        }
      }
      p {
        margin-top: 6vw;
        margin-bottom: 6vw;
      }
    }
  }

  .readMoreContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    cursor: pointer;

    .readMoreWrapper {
      position: relative;
      // width: 120px;
      // height: 120px;

      // max-width: 120px;
      // max-height: 120px;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      width: 3vw;

      .arrowDownScroll {
        width: 100%;
        height: auto;
        position: relative;
        &:nth-child(1) {
          right: -1vw;
        }
        &:nth-child(2) {
          transform: scaleX(-1);
          left: -1vw;
        }
        &:nth-child(3) {
          right: -1vw;
        }
        // @media (max-width: 991px) {
        //   width: 10vw;
        // }
      }
    }
    .readMoreImg {
      pointer-events: none;
      width: 100%;
      height: 100%;
    }
    .heartAssIcon {
      position: absolute;
      width: 40%;
      pointer-events: none;
    }
    @media (max-width: 991px) {
      position: relative;
      padding: 5vw 0;
      .readMoreWrapper {
        width: 8vw;
        height: auto;
      }
    }
  }
}
.bounce {
  -moz-animation: bounce 7s linear infinite;
  -webkit-animation: bounce 7s linear infinite;
  animation: bounce 7s linear infinite;
  @media (max-width: 991px) {
    -moz-animation: bounce 5s linear infinite;
    -webkit-animation: bounce 5s linear infinite;
    animation: bounce 5s linear infinite;
  }
}

@keyframes bounce {
  100% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(-30px);
  }
  // 60% {
  //   transform: translateY(-15px);
  // }
}
@media (max-width: 991px) {
  @keyframes bounce {
    100% {
      transform: translateY(0);
    }
    60% {
      transform: translateY(-10px);
    }
    // 60% {
    //   transform: translateY(-15px);
    // }
  }
}
