.RootFooter {
  background-color: theme-color('primary');
  height: auto;
  // padding: 250px 50px 50px 50px;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 2vw 0 3vw 0;
  // padding-top: 15vw;
  // padding-bottom: 3vw;
  .rowSection {
    @media (max-width: 991px) {
      max-width: 80%;
    }
  }
  @media (max-width: 991px) {
    margin-left: 0 !important;

    padding-bottom: 8vw;
  }

  .footerCol {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    // &.footerColNav,
    // &.footerColLegal {
    //   .listGroupItemFooter {
    //     &:nth-last-child(1) {
    //       padding-right: 0;
    //     }
    //   }
    // }

    &.footerColEmail {
      margin-top: 1vw;
      padding-left: 0;
      padding-right: 0;
      & > div {
        width: 100vw;
        background-color: theme-color('bgColor');
        margin-left: -5vw;
        margin-right: -5vw;

        padding: 1vw 10vw;

        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 991px) {
          margin-left: -10vw;
          margin-right: -10vw;
          padding: 3vw 10vw;
        }
      }
      a {
        transition: color 0.3s;
        font-size: 1vw;
        color: theme-color('primary');
        &:hover {
          color: theme-color('darkColor');
        }
        @media (max-width: 991px) {
          font-size: 3vw;
        }
      }
      @media (max-width: 991px) {
        margin-top: 6vw;
      }
    }
    .footerBrand {
      width: 20vw;
      max-width: 400px;

      margin: 3vw 0;

      border-radius: 0;

      @media (max-width: 991px) {
        width: 40vw;

        margin-bottom: 10vw;
      }
    }
    .listGroupFooter {
      // width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: inherit;
      @media (max-width: 991px) {
        flex-direction: column;
        // width: 100%;
      }
    }

    .footerColWrapper {
      // width: 100%;
      .footerColDiv {
        display: flex;
        align-items: center;
        // justify-content: center;

        &:nth-child(1) {
          padding-left: 3em;
        }
        @media (max-width: 991px) {
          justify-content: center;
          &:nth-child(1) {
            padding-left: 0;
          }
        }
        // &:nth-child(2) {
        //   justify-content: flex-start;
        // }

        .footerSubTitle .textStroke {
          -webkit-text-stroke: 1px theme-color('primary');
          // color: theme-color('primary');
        }
      }
    }
    .footerFaceText {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: normal;
      font-size: 0.8vw;
      line-height: 1.2vw;
      color: theme-color('textColorLight');
      @media (max-width: 1500px) and (min-width: 992px) {
        font-size: 0.9vw;
      }
      @media (max-width: 991px) {
        margin: 5vw 0 8vw 0;

        font-size: 3vw;
        line-height: 3.4vw;
      }
    }
    @media (max-width: 991px) {
      text-align: left;
    }
  }
}

.elementRootFloating {
  position: absolute;
  width: 5vw;

  &.elementRootFloatingLeft {
    left: 0;
  }
  &.elementRootFloatingRight {
    right: 0;
    transform: scaleX(-1);
  }

  @media (max-width: 991px) {
    width: 10vw;

    &.elementRootFloatingLeft {
      left: 1vw;
    }
    &.elementRootFloatingRight {
      right: 1vw;
    }
  }
}

// BUTTONS

.listGroupItemFooter a {
  text-decoration: none;
  color: theme-color('textColorLight');
  transition: 0.3s;
}

.listGroupItemFooter {
  background-color: transparent;
  border: none;
  // padding-right: 2vw;

  transition: opacity 0.3s;
  &:hover {
    // opacity: 0.5;

    a {
      color: theme-color('darkColor');
    }
  }

  &.emailText {
    @media (max-width: 991px) {
      margin-top: 3vw;
    }
  }

  @media (max-width: 991px) {
    padding: 1vw 3vw;
  }
}

.listGroupTitle {
  color: theme-color('textColorLight');

  padding: 0;
  //  padding-bottom: 1em;

  display: flex;
  align-items: center;
  text-transform: uppercase;
  @media (max-width: 991px) {
    // justify-content: center;
  }
}

.powered {
  margin-top: 2vw;
  @media (max-width: 991px) {
    margin: 5vw 0;
  }
}

.listGroupItemFooter a {
  cursor: pointer;
}

@media (max-width: 991px) {
  .RootFooter {
    padding-top: 8vw;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  .socialButtons button {
    width: 50px;
    height: 50px;
  }

  .socialButtonsToolbar .btn {
    padding: 0;
  }

  .copyText:nth-child(1) {
    margin-bottom: 10px;
  }

  // .brandFooter {
  //   margin-bottom: 70px;
  // }

  .interestLinks {
    margin-bottom: 30px;
  }
}
