#navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s linear;
  background-color: transparent;
  z-index: 999;
  height: 72px;
  // border-bottom: 1px solid theme-color('bgColor');

  &.navbarScrolled {
    background-color: theme-color('bgColor');

    .navCircle {
      fill: theme-color('primary');
    }

    .navLink,
    .navLink * {
      color: theme-color('primary');
    }
  }

  .navCollapse {
    @media (min-width: 992px) {
      height: 100%;
    }
  }

  @media (max-width: 991px) {
    height: auto;
  }
}
html[data-scroll-direction='down'] #navbar {
  transform: translate3d(0, -100%, 0);
}
html[data-scroll-direction='down'] .nav-promo {
  transform: translate3d(0, calc(-100% + 72px), 0);
}

.navContent {
  height: 100%;
  // margin-right: 10%;
  transition: 0.3s;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @media (max-width: 991px) {
    // display: block;
    width: 100%;
    height: auto;
  }
}

.navLink {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.5;
    &.linksNavDropDown {
      opacity: 1;
    }
  }
  &.linksNavDropDown {
    padding: 0 !important;
    margin-right: 0 !important;
  }
}
.navbarScrolled {
  .flagsDropDownMenu {
    background-color: theme-color('bgColor') !important;
  }
}

.flagsDropDown {
  background-color: transparent;

  .flagsDropDownToggle {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    position: relative;
    pointer-events: all;
    border: none;
    padding: 0.5rem 1rem;
    &:hover {
      box-shadow: none;
    }
    &:focus,
    &:active {
      background-color: transparent;
      box-shadow: none !important;
    }

    img {
      width: 1.5vw;
      max-width: 35px;

      // margin-right: 15px;

      @media (max-width: 991px) {
        width: 8vw;
      }
    }

    &::after {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .flagsDropDownMenu {
    min-width: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    padding: 0.5rem 1rem;
    .flagsDropDownItem {
      width: 100%;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: transparent;
      }
      img {
        width: 100%;
        max-width: 35px;
        // margin-right: 15px;
        @media (max-width: 991px) {
          width: 8vw;
        }
      }
    }
  }
}
#accessButton {
  transition: 0.3s;
}
.accessNavLink {
  transition: 0.3s;
  // .buttonText {
  //   font-size: 20px;
  //   line-height: 25px;
  // }
}

.navbar-light .navbar-nav .nav-link {
  color: theme-color('textColor') !important;
}
.navbar {
  padding: 0;
}

html[data-scroll-direction='down'] #navbar {
  transform: translate3d(0, -100%, 0);
}
html[data-scroll-direction='down'] .nav-promo {
  transform: translate3d(0, calc(-100% + 72px), 0);
}
.navbarLogo {
  max-width: 20vw;
  width: 100%;
  border-radius: 0;

  transition: max-width 0.3s;
  @media (max-width: 991px) {
    max-width: 30vw;
  }
}

#navbar {
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s ease-in-out;
  background-color: transparent;
}
.navbarScrolled {
  background-color: theme-color('bgColor') !important;
  border-color: theme-color('titleColor') !important;

  .navbarLogo {
    @media (min-width: 992px) {
      max-width: 10vw;
    }
  }
}

.navbar-light .navbar-nav .nav-link {
  color: theme-color('textColor');
  margin: 0 1vw;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: 0.3s;

  text-align: center;
  letter-spacing: 0.195em;
  text-transform: uppercase;
}

#navLinksWhite .nav-link {
  color: theme-color('textColor') !important;
}

.navbar-light .navbar-nav .nav-link {
  color: theme-color('textColor') !important;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: theme-color('primary') !important;
}

.nav-color-top {
  background-color: transparent;
  transition: 1s;
}

.nav-color-scroll {
  // background-color: theme-color('primary');

  @media (max-width: 991px) {
    padding: 0 5vw !important;
    &.navExpanded {
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(49, 175, 180, 0.8)' stroke-width='2' stroke-linecap='butt' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
      }
      .navLink,
      .navLink * {
        color: theme-color('primary') !important;
      }
    }
  }
}

.bold {
  font-weight: 700;
}

.brandName {
  color: theme-color('textColor') !important;
  position: relative;
  left: 10%;
  transition: 0.3s;
}

.brandNameWhite {
  display: flex;
  align-items: center;
  color: #fff !important;
  position: relative;
  left: 10%;
  transition: 0.3s;
  height: 100%;
}

#navbar {
  transition: top 0.3s, 0.5s;
}

.navbar--hidden {
  top: -50px;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.8)' stroke-width='2' stroke-linecap='butt' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-collapse {
  right: 86px;
}

.navBrandLogo {
  width: 100%;
  height: auto;
  position: relative;
  top: 30px;
  transition: 0.3s;
}

//TOGGLER
.navbar-toggler {
  border: none;
  font-size: 2em;
}

/* SQUARE EFFECT */

.curseLink {
  width: 162px;
  height: 50px;
  // border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .curseLink::before,
// .curseLink::after {
//    box-sizing: inherit;
//    content: '';
//    position: absolute;
//    width: 100%;
//    height: 100%;
//    border: 2px solid transparent;
//    width: 0;
//    height: 0;
// }

// .curseLink::before {
//    bottom: 0;
//    left: 0;
// }

// .curseLink::after {
//    top: 0;
//    right: 0;
// }

// .curseLink:hover {
//    color: theme-color('primary');
//    background-color: rgba(0, 0, 0, 0.199);
// }

// .curseLink:hover::before,
// .curseLink:hover::after {
//    width: 100%;
//    height: 100%;
// }

// .curseLink:hover::before {
//    border-bottom-color: theme-color('primary');
//    border-right-color: theme-color('primary');
//    -webkit-transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
//    transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
// }

// .curseLink:hover::after {
//    border-top-color: theme-color('primary');
//    border-left-color: theme-color('primary');
//    -webkit-transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s,
//       height 0.25s ease-out 0.75s;
//    transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s,
//       height 0.25s ease-out 0.75s;
// }

/* DESKTOP STYLE */

@media (max-width: 1400px) {
  .curseLink {
    right: 50px !important;
  }

  .brandName {
    left: 5%;
  }

  .brandNameWhite {
    left: 5%;
  }

  // .navContent {
  //   margin-right: 0;
  // }
}

@media (min-width: 992px) and (max-width: 1100px) {
  .brandNameWhite {
    margin-right: 100px;
  }
}
@media (min-width: 992px) {
  // /* UNDERLINE EFFECT */
  // .justify-content-center {
  //   margin: 0 auto;
  // }

  // .navLink {
  //   position: relative;
  //   transition: 0.5s;
  // }

  // .linksNav span {
  //   position: relative;
  //   padding: 10px 0;
  // }

  // .linksNav span::after {
  //   position: absolute;
  //   content: '';
  //   width: 100%;
  //   height: 1px;
  //   top: 100%;
  //   left: 0;
  //   background: theme-color('textColor');
  //   transition: transform 0.5s;
  //   transform: scaleX(0);
  //   transform-origin: right;
  // }
  // .linksNavBottom::after {
  //   background: theme-color('secondary') !important;
  // }

  // .linksNav:hover span::after {
  //   transform: scaleX(1);
  //   transform-origin: left;
  // }

  // .linksNav:focus span::after {
  //   transform: scaleX(1);
  // }
}

.navbarScrolled {
  .navbarLogo {
    path {
      transition: fill 0.3s;
      // fill: theme-color('titleColor');
    }
  }
  @media (max-width: 991px) {
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(49, 175, 180, 0.8)' stroke-width='2' stroke-linecap='butt' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }
}
.collapsed ~ .navBrand .svg path {
  fill: theme-color('titleColor') !important;
}

/* RESPONSIVE STYLE */

@media (max-width: 991px) {
  #navbar {
    height: 72px;
    padding-left: 0;
    padding-right: 0;
  }

  #containerNavToggle {
    display: flex;
  }

  .navBrand {
    margin-left: 3vw;
    .navbarLogo {
      // position: absolute;
      width: 30vw;
    }
  }
  .navLink {
    width: 100%;
  }

  .nav-link {
    padding: 4vw 3vw;
  }

  .nav-link:hover {
    background-color: rgba(0, 0, 0, 0.541);
    color: theme-color('primary');
  }

  .navbar-light .navbar-nav .nav-link {
    margin-right: 0;
  }

  .navbar-collapse {
    position: initial;
    right: 0;
  }

  .brandName,
  .brandNameWhite {
    position: absolute;
    left: 20px;
    top: 0;
  }
  .navbar-toggler-icon {
    width: 10vw;
    height: 10vw;
    max-width: 50px;
    max-height: 50px;
  }

  // .custom-toggler.navbar-toggler {
  //   border-color: rgb(255, 102, 203);
  // }

  .navbar-toggler {
    margin-right: 20px;

    @media (max-width: 991px) {
      margin: 0;
    }
  }

  .mobileButton {
    position: absolute;
    right: 100px;
    top: 12.5px;
  }

  .curseLink {
    width: 120px;
    height: 40px;
    right: 0 !important;
  }

  .brandNameWhite svg {
    width: 150px;
    transition: 0.5s;
  }

  .navbar-collapse {
    background-color: theme-color('bgColor');
    border-bottom: 1px solid theme-color('titleColor');
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    // padding-top: 72px;
    z-index: -1;
  }

  .navbarScrolled {
    .navbar-collapse {
      background-color: theme-color('bgColor');
      border-bottom: 1px solid theme-color('titleColor');
      // position: relative;
      left: 0;
      top: 0;
      width: 100%;
      // padding-top: 72px;
      z-index: -1;
    }
  }
  .collapseNavHeight {
    height: 72px;
  }
}

@media (max-width: 420px) {
  .brandNameWhite svg {
    width: 100px;
  }
}
