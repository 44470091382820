.lottieContainer {
  position: absolute;
  width: 100vw;
  //   height: 100vh;
  top: 0;
  //   top: 50%;
  //   transform: translateY(-50%) scaleX(-1);
  // left: 0;

  // &.lottieReversed {
  //   transform: scale(-1);
  //   top: auto;
  //   bottom: 0;
  // }

  @media (max-width: 991px) {
    height: 100vh;
  }
}
