/*************************************************************************
*
* Customize Bootstrap 4 with our new built-in Sass variables for 
* global style preferences for easy theming and component changes.
*
* Please read more at https://getbootstrap.com/docs/4.0/getting-started/theming/
* 
*************************************************************************/

@debug rgba(#fff, 0.8);

$theme-colors: (
  primary: #31afb4,
  secondary: #115571,
  buttonsBg: #31afb4,
  buttonsColor: #ffffff,
  bgColor: #fff,
  bgSecondary: #c7dbf4,
  bgGradient:
    linear-gradient(
      to right,
      #31afb4 0%,
      // #319ab4 30%,
      // #319ab4 60%,
      #115571 100%
    ),
  cardBg: #ffffff,
  cardBgOpacity: #ffffff67,
  textColor: #000,
  textColorLight: #fff,
  darkColor: #1b6c6e,
);
